(function ($) {

    $('.event__url').click(function () {
      var outerWrapper = $(this).parents('.event__detail-wrapper')
      var eventListItem = $(this).parents('.event__detail')
      var slidingForm = eventListItem.find('.event__form')
      slidingForm.css({
        top: eventListItem.position().top + eventListItem.outerHeight(),
        left: outerWrapper.position().left,
        width: outerWrapper.outerWidth()
      })

      var otherOpenedForms = $('.event__form')
        .filter(function (index, element) {
          return !$(element).is(slidingForm) && $(element).is(':visible')
        })

      $('.event__url').not(this).removeClass('active')
      $(this).toggleClass('active')

      if (otherOpenedForms.length > 0) {
        otherOpenedForms.slideUp(400, function () {
          slidingForm.stop().slideToggle()
        })
      } else {
        slidingForm.stop().slideToggle()
      }

      return false;
    });

    $(window).load(function () {
        var $error = $('.event__form.error');
        if ($error.length) {
            $error.removeClass('hidden');
            $error.parent().find('.event__url').addClass('active');
            var $scroll = $error.parent().offset().top;
            $("html, body").animate({scrollTop: $scroll});
        }
    });

})(jQuery);
