(function ($) {
    $('.slide .team__title').each(function () {
        $this = $(this);
        $($this).click(function () {
            $this = $(this);
            $this.toggleClass('active');
            $parent = $this.parent().find('.team__members');
            $parent.toggleClass('active');
            if ($parent.height() > 0)
                $parent.css({'height': 0});
            else
                $parent.css({'height': $parent.data('inheritHeight')});
        });
    });
    $(document).ready(function () {
        $('.slide .team__members').each(function () {
            $this = $(this);
            $this.data('inheritHeight', $this.height()).height(0);
        });
    });
})(jQuery);
