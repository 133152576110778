(function ($) {

    $('.c-imagetextfull__button').click(function () {
        $this = $(this);
        if ($this.parent().hasClass('play')) {
            $this.parent().find('.c-imagetextfull__video').get(0).pause();
            $this.parent().removeClass('play');
        } else {
            $this.parent().find('.c-imagetextfull__video').get(0).play();
            $this.parent().addClass('play');
        }

    });

})(jQuery);
