(function ($) {

  var $centeredtext = $('.c-centeredtext');
  var $quote = $('.c-quote');
  var $timebarItem = $('.c-timebar__item');
  var $timebar = $('.c-timebar');
  var $timebarMaxHeight = $timebar.outerHeight();

  $(document).ready(function () {
    if ($('body').hasClass('mobile')) {
      $timebar.height(100);
    }
    $('.js--scroll-down').click(function(){
      var href = $(this).attr('href').split('#')[1];
      $('html,body').animate({
          scrollTop: $('#'+href).offset().top},
        'slow');
      return false;
    });
  });

  $(window).scroll(function () {
    // $('.c-headline-box').each(function () {
    //     if (isScrolledIntoView($(this))) {
    //         $(this).addClass('scrolly');
    //     }
    // });
    // $centeredtext.each(function () {
    //     if (isScrolledIntoView($(this))) {
    //         $(this).find('.c-centeredtext__title').addClass('scrolly');
    //     }
    // });
    $quote.each(function () {
      if (isScrolledIntoView($(this))) {
        $(this).find('.c-quote__inner').addClass('scrolly');
      }
    });
    $timebarItem.each(function () {
      $this = $(this);
      if (isScrolledIntoViewLarge($this)) {
        $this.addClass('scrolly');
      }
    });
    if ($timebar.length && $('body').hasClass('mobile')) {
      var st = $(window).scrollTop() + $(window).height() - 50;
      if (st >= $timebar.offset().top) {
        if ($timebar.height() <= $timebarMaxHeight) {
          $timebar.height(st - $timebar.offset().top);
        }
      }
    }


  });


  function isScrolledIntoView(elem) {

    var $elem = $(elem);

    var $window = $(window);

    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();

    var elemTop = $elem.offset().top;
    var elemBottom = $elem.offset().top + $elem.height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }

  function isScrolledIntoViewLarge(elem) {

    var $elem = $(elem);

    var $window = $(window);

    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();

    var elemTop = $elem.offset().top;
    var elemBottom = $elem.offset().top + $elem.height() / 2;

    return ((elemBottom <= docViewBottom));
  }
})(jQuery);