(function ($) {
  $tagcloud = $('.tagcloud');
  $tagcloudItem = $('.tagcloud .cloud');
  $events = $('.eventlist__event');
  $searchFieldFilter = $('.c-workshop__search__field input');
  $sort = $('.c-workshop__search__sort select');

  var dataArr = [];


  $tagcloudItem.change(function () {
    dataArr = [];
    $tagcloudItem.each(function () {
      dataObj = {};
      dataObj[$(this).val()] = $(this).val();
      dataArr.push(
        dataObj
      );
    });


    $this = $(this);
    data = $this.val();
    var newval = [];
    $.each(dataArr, function (key, val) {
      $.each(val, function (value, key) {
        if (key != '-' && key != 'Alle')
          newval.push(key.replace(' ','_'));


      });


    });
    if (newval.length) {
      newval = newval.join('.');
      $events.stop().fadeOut(
        function () {
          if (!$events.is(':visible')) {
            $('.workshopErrorBox').stop().fadeIn();
          } else {
            $('.workshopErrorBox').stop().fadeOut();
          }
        }
      );
      $('.' + newval).stop().fadeIn();
    } else {
      $events.stop().fadeIn();
    }
    return false;
  });

  $searchFieldFilter.keyup(function () {
    value = $searchFieldFilter.val();
    filterTag(value);

  });

  $sort.change(function () {
    value = $(this).val();

    switch (value) {
      case "Preis aufsteigend":
        $events.tsort({attr: 'data-price'});
        break;
      case "Preis absteigend":
        $events.tsort({attr: 'data-price', order: 'desc'});
        break;
      case "Alphabetisch A-Z":
        $events.tsort();
        break;
      case "Alphabetisch Z-A":
        $events.tsort({order: 'desc'});
        break;
      default:


    }


  });

  $(document).ready(function () {
    hash = window.location.hash.replace('#', '');
    filterTag(hash);
    if (!$events.is(':visible')) {
      $('.workshopErrorBox').fadeIn();
    } else {
      $('.workshopErrorBox').fadeOut();
    }
  });
  window.onhashchange = function () {
    hash = window.location.hash.replace('#', '');
    filterTag(hash);
    if (!$events.is(':visible')) {
      $('.workshopErrorBox').fadeIn();
    } else {
      $('.workshopErrorBox').fadeOut();
    }
  };

  function filterTag(value) {
    checker = 0;
    if (value) {
      value = value.toLowerCase();
      $events.each(function () {
        $this = $(this);
        matchString = '/('
        classname = $this.data('searchable').toLowerCase();
        values = value.replace(' ', '|');
        if (new RegExp(values).test(classname)) {
          $this.stop().fadeIn();
        } else {
          $this.stop().fadeOut(function () {
            if (!$events.is(':visible')) {
              $('.workshopErrorBox').stop().fadeIn();
            } else {
              $('.workshopErrorBox').stop().fadeOut();
            }
          });
        }
      });
    } else {
      $events.stop().fadeIn();
    }


  }

})(jQuery);

