(function ($) {

  // $search = $('.c-search');
  // $searchBtn = $('.c-search--button');
  // $searchField = $('.c-search--field');
  // $mainnav = $('.c-mainnav');
  // $overlay = $('.overlay');
  //
  // $searchBtn.click(function () {
  //     if ($searchField.val()) {
  //         return true;
  //     } else {
  //         if ($search.hasClass('active')) {
  //             hideSearch();
  //             return false;
  //         } else {
  //             $searchBtn.find('.fa-search').addClass('fa-close');
  //             $searchField.removeClass('hidden').focus();
  //             $search.addClass('active');
  //             $mainnav.addClass('hidden');
  //             $overlay.addClass('active');
  //
  //         }
  //         return false;
  //     }
  // });
  //
  // $searchBtn.on("mousedown", function (e) {
  //     $searchBtn.data("mouseDown", true);
  // });
  //
  // $searchBtn.on("mouseup", function (e) {
  //     $searchBtn.data("mouseDown", false);
  // });
  //
  // $searchField.blur(function () {
  //     if ($searchBtn.data("mouseDown") != true) {
  //         hideSearch()
  //     }
  // });
  // $(document).keyup(function (e) {
  //     if (e.keyCode == 27) {
  //         hideSearch();
  //     }
  // });
  //
  // function hideSearch() {
  //     $searchBtn.find('.fa-search').removeClass('fa-close');
  //     $overlay.removeClass('active');
  //     $search.removeClass('active');
  //     $mainnav.removeClass('hidden');
  //     $searchField.addClass('hidden').val('');
  // }
  var $_GET = {};

  document.location.search.replace(/\??(?:([^=]+)=([^&]*)&?)/g, function () {
    function decode (s) {
      return decodeURIComponent(s.split("+").join(" "));
    }

    $_GET[decode(arguments[1])] = decode(arguments[2]);
  });
  if (typeof $_GET['keywords'] != 'undefined') {
    if ($_GET['keywords'].length > 0) {
      $.get('http://photopreim.local/ajax_search?keywords=' + $_GET['keywords'], function (response) {
        console.log(response);
      });
    }
  }
  var $searchForm = document.querySelector('.c-search--field');
  $searchForm.addEventListener('awesomplete-selectcomplete', function (e) {
    document.querySelector('.productsearch.c-search form').submit();
  });


})(jQuery);

