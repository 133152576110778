(function ($) {

  $isoproductlist = $('.js-productgrid');
  $isoswitchButton = $('.js-switchgrid__button');
  $isoswitchButton.click(function () {
    $this = $(this);
    if ($this.data('layout') == 'list') {
      $isoproductlist.addClass('c-productgrid--list');
      $isoswitchButton.removeClass('button--active');
      document.cookie = "iso_layout=list;path=/;";
      $this.addClass('button--active');
    } else if ($this.data('layout') == 'tiles') {
      $isoproductlist.removeClass('c-productgrid--list');
      $isoswitchButton.removeClass('button--active');
      $this.addClass('button--active');
      document.cookie = "iso_layout=tiles;path=/;";
    }
    return false;
  });

  $(document).ready(function () {
    hash = window.location.hash.replace('#', '');
    isolayout(hash);
    if (getCookie('iso_layout') == 'list') {
      isolayout('list');
    } else if (getCookie('iso_layout') == 'tiles') {
      isolayout('tiles');
    }
  });
  window.onhashchange = function () {
    hash = window.location.hash.replace('#', '');
    isolayout(hash);
  };

  function isolayout (trigger) {
    if (trigger == 'list') {
      $isoswitchButton.removeClass('button--active');
      $isoproductlist.addClass('c-productgrid--list');
      $('.js-switchgrid__list').addClass('button--active');
      document.cookie = "iso_layout=list;path=/;";
    } else if (trigger == 'tiles') {
      $isoswitchButton.removeClass('button--active');
      $isoproductlist.removeClass('c-productgrid--list');
      $('.js-switchgrid__tiles').addClass('button--active');
      $('.js-switchgrid__tiles').addClass('button--active');
      document.cookie = "iso_layout=tiles;path=/;";
    }

  }

  function getCookie (name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
  }

})(jQuery);
