const $selectField = document.getElementById('ctrl_billingaddress_country')
const $targetField = document.getElementById('ctrl_billingaddress_vat_no')
checkValues();
$selectField.addEventListener("change", checkValues);

function checkValues() {
  if ($selectField.options[$selectField.selectedIndex].value === 'de') {
    $targetField.disabled = true;
    $targetField.parentElement.style.display = 'none';
  } else {
    $targetField.disabled = false;
    $targetField.parentElement.style.display = 'flex';
  }
}
