(function ($) {
  $mobileNav = $('.c-mobilenav');
  $shopnav = $('.c-meganav.mobile');
  $('.c-mobilenav__item__more').click(function () {
    $this = $(this);
    $this.toggleClass('active');
    $('.c-mainnav').toggleClass('active');
    $('body').toggleClass('stop-scroll');
    if ($('html').hasClass('stop-scroll')) {
      $('html').removeClass('stop-scroll');
    } else {
      $('html').addClass('stop-scroll');
    }
    $mobileNav.removeClass('scroll');
    $shopnav.removeClass('active');
    if ($('#offcanvastop').length) {
      $offcanvasTop = $('#offcanvastop');
      $offcanvasTop.toggleClass('mobile');
      if ($offcanvasTop.hasClass('active')) {
        $body.css({'padding-top': 0});
        $offcanvasTop.css({'top': -$offcanvasTop.outerHeight()});
        $offcanvasTop.removeClass('active');
        $mainNav.css({'top': 0});
        toggler = 0;
      }
    }
    return false;
  });

  $('.shopping-cart').click(function () {
    $shopnav.toggleClass('active');
    $('body').toggleClass('stop-scroll');
    if ($('html').hasClass('stop-scroll')) {
      $('html').removeClass('stop-scroll');
    } else {
      $('html').addClass('stop-scroll');
    }
    $mobileNav.removeClass('scroll');
    $('.c-mainnav').removeClass('active');
    $('.c-mobilenav__item__more').removeClass('active');
    return false;

  });

  var st = 0;
  $(window).scroll(function () {
    $this = $(this);
    if (!$('.c-mainnav').hasClass('active')) {
      if ($this.scrollTop() > (st + 100)) {
        $mobileNav.addClass('scroll');
        st = $this.scrollTop();
      } else if ($this.scrollTop() < st) {
        $mobileNav.removeClass('scroll');
        st = $this.scrollTop();
      } else if ($this.scrollTop() + $this.height() >= $(document).height()) {
        $mobileNav.removeClass('scroll');
        st = $this.scrollTop();
      }
    } else {
      $mobileNav.removeClass('scroll');
    }
  });
})(jQuery);
