(function ($) {
  $breadcrumbItem = $('.mod_breadcrumb li');
  if ($breadcrumbItem.length) {
    $breadcrumbItem.each(function () {
      var $activeItem = $(this).text();
      $activeItem = $activeItem.replace(/(\r\n|\n|\r)/gm, '');
      var activeId = $('.c-shopcategories__accordion').find('label[title="' + $activeItem + '"]').attr('for');
      if (typeof activeId != "undefined") {
        activeId = activeId.split(' ');
        activeId = activeId[activeId.length - 1];

        $('#' + activeId).prop('checked', true);
      }

    });
  }
  $(document).ready(function () {
    var $filter = $('#left');
    var initHeight = $filter.height();
    if ($('#left .inside').height() > 0) {
      var maxHeight = $filter.find('.inside').outerHeight();
      $filter.click(function () {
        if ($filter.hasClass('active')) {
          $filter.height(initHeight);
          $filter.removeClass('active');
        } else {
          $filter.height(parseInt(maxHeight + (2 * initHeight)));
          $filter.addClass('active');
        }
      });
    } else {
      $filter.remove();
    }
  });

  $('label.c-shopcategories__accordion__link').click(function () {
    var $this = $(this);
    $activeAttr = $this.attr('for');
    $('.c-shopcategories__accordion__item input:checked').each(function () {
      if ($(this).data('parentlevel') === $('#' + $activeAttr).data('parentlevel')) {
        $(this).prop('checked', false);
        $(this).parent('li').find('input').prop('checked', false)
      }
    });
    if ($('#' + $activeAttr).prop('checked') == true) {
      $('#' + $activeAttr).prop('checked', false);
    } else {
      $('#' + $activeAttr).prop('checked', true);
    }
    return false;

  });

})
(jQuery);
